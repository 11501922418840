(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-action-listener.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-action-listener.js');
"use strict";

const {
  useEffect,
  useCallback,
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  useHistory
} = ReactRouterDOM;
const {
  log: {
    getLogger
  },
  userSession
} = svs.core;
const {
  actions,
  PARAM_ACTION,
  PARAM_AMOUNT,
  PARAM_EVENT_NUMBER
} = svs.components.sport.tipsenShared;
const {
  useIsCurrentDrawPlayable
} = svs.components.tipsen.hooks;
const {
  selectCoupon,
  selectCouponStatus
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  COUPON_VALID
} = svs.components.tipsen.engine.constants.couponStatus;
const {
  selectActiveWallet
} = svs.components.tipsen.walletSelectors;
const logger = getLogger('tipsen:useActionListener');

const useCouponState = couponId => {
  const isDrawPlayable = useIsCurrentDrawPlayable();
  const coupon = useSelector(state => couponId && selectCoupon(state, couponId));
  const couponStatus = useSelector(state => couponId && selectCouponStatus(state, couponId));
  const isCouponValid = couponStatus === COUPON_VALID;
  const isPayable = isDrawPlayable && isCouponValid;
  const isLoadingExternal = (coupon === null || coupon === void 0 ? void 0 : coupon.isExternal) && !(coupon !== null && coupon !== void 0 && coupon.guid);
  return useMemo(() => ({
    isPayable,
    isLoadingExternal
  }), [isPayable, isLoadingExternal]);
};

const useWalletState = () => {
  const isUserLoggedIn = userSession.hasRole(userSession.roles.PLAYER);
  const activeWallet = useSelector(selectActiveWallet);
  const isAvailable = Boolean(activeWallet);
  const isLoading = isUserLoggedIn && !activeWallet;
  return useMemo(() => ({
    isAvailable,
    isLoading
  }), [isAvailable, isLoading]);
};

const _runChecks = listOfChecks => {
  const messages = listOfChecks.filter(_ref => {
    let {
      isApplicable
    } = _ref;
    return isApplicable;
  }).map(_ref2 => {
    let {
      message
    } = _ref2;
    return message;
  });
  return messages.join(', ');
};

const _processPayAction = _ref3 => {
  let {
    walletState,
    couponState,
    placeBetAsync
  } = _ref3;
  const pendingText = _runChecks([{
    message: 'waiting for wallet',
    isApplicable: walletState.isLoading
  }, {
    message: 'waiting for external coupon',
    isApplicable: couponState.isLoadingExternal
  }]);
  if (pendingText) {
    logger.debug("Action PAY is pending (".concat(pendingText, ")"));
    return {
      isPending: true
    };
  }
  const errorsText = _runChecks([{
    message: 'missing user-login',
    isApplicable: !userSession.hasRole(userSession.roles.PLAYER)
  }, {
    message: 'coupon is not payable',
    isApplicable: !couponState.isPayable
  }, {
    message: 'missing wallet',
    isApplicable: !walletState.isAvailable
  }, {
    message: 'missing function placeBetAsync()',
    isApplicable: typeof placeBetAsync !== 'function'
  }]);
  if (errorsText) {
    logger.info("Skipping action PAY (".concat(errorsText, ")"));
    return;
  }
  window.scrollTo({
    left: 0,
    top: document.body.scrollHeight,
    behavior: 'smooth'
  });
  placeBetAsync().catch(error => {
    logger.info("placeBetAsync() failed in action PAY (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
  });
};

const _processOpenQuickplayAction = _ref4 => {
  let {
    qs
  } = _ref4;
  if (!qs.has(PARAM_AMOUNT)) {
    logger.info('Skipping action OPEN_PIX: No amount given');
    return;
  }
  const amount = parseInt(qs.get(PARAM_AMOUNT) || '', 10);
  qs.delete(PARAM_AMOUNT);
  if (!amount || amount < 0) {
    logger.info("Skipping action OPEN_PIX: Invalid amount given (amount: ".concat(amount, ")"));
    return;
  }
  const pixElement = document.querySelector("[data-pix-amount=\"".concat(amount, "\"]"));
  if (!pixElement) {
    logger.info("Skipping action OPEN_PIX: Can't find pix-element matching given amount (amount: ".concat(amount, ")"));
    return;
  }
  pixElement.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  });
  pixElement.dispatchEvent(new CustomEvent('tipsenOpenPixModal'));
};
const _processOpenDrawSelector = () => {
  const drawManagerElement = document.querySelector('.pg_draw_manager');
  if (!drawManagerElement) {
    logger.info("Skipping action OPEN_DRAW_SELECTOR: DrawManager element not found");
    return;
  }
  drawManagerElement.dispatchEvent(new CustomEvent('tipsenOpenDrawSelector'));
};
const _processCloseModal = _ref5 => {
  let {
    qs
  } = _ref5;
  if (!qs.has(PARAM_EVENT_NUMBER)) {
    logger.info('Skipping action CLOSE_MODAL No event given');
    return;
  }
  const event = "event-".concat(qs.get(PARAM_EVENT_NUMBER));
  const elemtToScrollTo = document.getElementById(event);
  if (elemtToScrollTo) {
    elemtToScrollTo.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }
};

const useActionListener = function () {
  let inputBag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    couponId,
    placeBetAsync
  } = inputBag;

  const history = useHistory();
  const couponState = useCouponState(couponId);
  const walletState = useWalletState();

  const _processAction = useCallback(_ref6 => {
    let {
      action,
      qs
    } = _ref6;
    try {
      switch (action) {
        case actions.PAY:
          return _processPayAction({
            couponState,
            walletState,
            placeBetAsync
          });
        case actions.OPEN_PIX:
          return _processOpenQuickplayAction({
            qs
          });
        case actions.OPEN_DRAW_SELECTOR:
          return _processOpenDrawSelector();
        case actions.CLOSE_MODAL:
          return _processCloseModal({
            qs
          });
        default:
          logger.info("Unknown action \"".concat(action, "\""));
      }
    } catch (error) {
      logger.info("Failed to process action \"".concat(action, "\" (error \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    }
  }, [couponState, walletState, placeBetAsync]);
  useEffect(() => {
    try {
      const qs = new URLSearchParams(history.location.search);
      if (!qs.has(PARAM_ACTION)) {
        return;
      }
      const allActions = qs.getAll(PARAM_ACTION);
      const pendingActions = allActions.filter(action => {
        var _processAction2;
        return (_processAction2 = _processAction({
          action,
          qs
        })) === null || _processAction2 === void 0 ? void 0 : _processAction2.isPending;
      });
      if (pendingActions.length === allActions.length) {
        return;
      }
      qs.delete(PARAM_ACTION);
      pendingActions.forEach(action => qs.append(PARAM_ACTION, action));
      history.replace({
        search: qs.toString()
      });
    } catch (error) {
      logger.info("Failed to process actions (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    }
  }, [history, _processAction]);
};

setGlobal('svs.components.tipsen.hooks.useActionListener', useActionListener);

 })(window);