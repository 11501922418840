(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-draw-results.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-draw-results.js');
"use strict";


const {
  useMemo,
  useRef
} = React;
const {
  useDraw
} = svs.components.sport.drawsRedux.hooks;
const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  DrawState
} = svs.components.sport.tipsenShared;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('tipsen:useDrawResult');
const shouldFetchForcast = drawStateId => drawStateId >= DrawState.Cancelclosed && drawStateId < DrawState.Finalized;
const useDrawResults = function (_ref) {
  let {
    productId,
    drawNumber
  } = _ref;
  let {
    skip = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const definition = useDefinition();
  const shouldFetchJackpot = (definition === null || definition === void 0 ? void 0 : definition.hasJackpot) || false;
  const draw = useDraw({
    productId,
    drawNumber
  }, {
    skip,
    shouldFetchJackpot
  });
  const fetchForcast = shouldFetchForcast(draw === null || draw === void 0 ? void 0 : draw.drawStateId) && !skip;
  const fetchResult = (draw === null || draw === void 0 ? void 0 : draw.drawStateId) >= DrawState.Finalized && !skip;
  const hasSubscribedToDraw = useRef(false);
  const drawResultResponse = tipsenApi.useGetDrawResultQuery({
    drawNumber,
    productId
  }, {
    skip: !fetchResult
  });
  const shouldContinueSubscribeToForecast = (drawResultResponse.isLoading || drawResultResponse.isError) && hasSubscribedToDraw.current;
  const drawForecastResponse = tipsenApi.useGetDrawForecastQuery({
    drawNumber,
    productId
  }, {
    skip: !(fetchForcast || shouldContinueSubscribeToForecast)
  });
  return useMemo(() => {
    let drawResults = null;
    let multiForecast = null;
    let winDivisions = null;
    let cancelled = false;
    let errorObject = null;
    let changes = null;
    if (fetchForcast || shouldContinueSubscribeToForecast) {
      if (drawForecastResponse.isSuccess && drawForecastResponse.currentData && Object.keys(drawForecastResponse.currentData).length > 0) {
        var _drawForecastResponse;
        drawResults = drawForecastResponse.currentData.drawResults;
        winDivisions = drawForecastResponse.currentData.winresult;
        multiForecast = drawForecastResponse.currentData.multiForecast;
        changes = drawForecastResponse.currentData.changes;
        cancelled = Boolean((_drawForecastResponse = drawForecastResponse.currentData) === null || _drawForecastResponse === void 0 ? void 0 : _drawForecastResponse.cancelled);
        hasSubscribedToDraw.current = true;
      } else if (drawForecastResponse.isError) {
        logger.debug('Error fetching draw forecast', drawForecastResponse.error);
        errorObject = drawForecastResponse.error;
      }
    }
    if (fetchResult) {
      if (drawResultResponse.isSuccess && drawResultResponse.currentData) {
        drawResults = drawResultResponse.currentData.events;
        winDivisions = drawResultResponse.currentData.winresult;
        cancelled = Boolean(drawResultResponse.currentData.cancelled);
      } else if (drawResultResponse.isError && !shouldContinueSubscribeToForecast) {
        logger.debug('Error fetching draw result', drawResultResponse.error);
        errorObject = drawResultResponse.error;
      }
    }
    return {
      error: errorObject,
      data: [drawResults, winDivisions, multiForecast, cancelled, changes]
    };
  }, [drawForecastResponse.currentData, drawForecastResponse.error, drawForecastResponse.isError, drawForecastResponse.isSuccess, drawResultResponse.currentData, drawResultResponse.error, drawResultResponse.isError, drawResultResponse.isSuccess, fetchForcast, fetchResult, shouldContinueSubscribeToForecast]);
};
setGlobal('svs.components.tipsen.hooks.useDrawResults', useDrawResults);

 })(window);