(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-get-selected-event-number.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-get-selected-event-number.js');
"use strict";

const {
  selectDrawsByProduct
} = svs.components.tipsen.selectors;
const {
  useQueryParam
} = svs.components.lbUtils;
const {
  useSelector
} = ReactRedux;
const useGetSelectedEventNumber = () => {
  var _productDraws$, _productDraw$drawEven;
  const queryEventNumber = useQueryParam('event');
  const productDraws = useSelector(selectDrawsByProduct);
  let eventNumber;
  const productDraw = (_productDraws$ = productDraws === null || productDraws === void 0 ? void 0 : productDraws[0]) !== null && _productDraws$ !== void 0 ? _productDraws$ : null;
  if (queryEventNumber) {
    eventNumber = Number(queryEventNumber);
  } else if (productDraw !== null && productDraw !== void 0 && (_productDraw$drawEven = productDraw.drawEvents) !== null && _productDraw$drawEven !== void 0 && _productDraw$drawEven[0]) {
    eventNumber = productDraw.drawEvents[0].eventNumber;
  }
  return eventNumber;
};
setGlobal('svs.components.tipsen.hooks.useGetSelectedEventNumber', useGetSelectedEventNumber);

 })(window);