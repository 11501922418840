(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-is-at-bottom-window.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-is-at-bottom-window.js');
"use strict";

const {
  useState,
  useEffect
} = React;
const comparativeValue = 0.95;
const {
  throttle
} = _;
const useIsAtBottomWindow = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);
  useEffect(() => {
    const checkIfAtBottom = () => {
      const windowInnerHeight = window.innerHeight;
      const pageYOffset = window.pageYOffset;
      const documentHeight = document.body.offsetHeight;
      setIsAtBottom((windowInnerHeight + pageYOffset) / documentHeight >= comparativeValue);
    };
    const checkIfAtBottomThrottled = throttle(checkIfAtBottom, 200);
    window.addEventListener('scroll', checkIfAtBottomThrottled);
    window.addEventListener('resize', checkIfAtBottomThrottled);
    checkIfAtBottomThrottled();
    return () => {
      window.removeEventListener('scroll', checkIfAtBottomThrottled);
      window.removeEventListener('resize', checkIfAtBottomThrottled);
    };
  }, []);
  return isAtBottom;
};
setGlobal('svs.components.tipsen.hooks.useIsAtBottomWindow', useIsAtBottomWindow);

 })(window);