(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-get-match-is-not-started-or-live.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-get-match-is-not-started-or-live.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  getIsNotStarted,
  getIsOngoing,
  getIsFinished,
  getIsCancelled
} = svs.components.sportinfo.common.matchState;
const {
  selectSportEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const useGetIsMatchNotStartedOrLive = matchId => {
  const sportEvent = useSelector(state => selectSportEvent(state, matchId));
  const sportEventStatus = sportEvent === null || sportEvent === void 0 ? void 0 : sportEvent.sportEventStatus;
  const isNotStarted = getIsNotStarted(sportEventStatus);
  const isOngoing = getIsOngoing(sportEventStatus);
  const isFinished = getIsFinished(sportEventStatus);
  const isCancelled = getIsCancelled(sportEventStatus);
  return Boolean((isNotStarted || isOngoing) && !isFinished && !isCancelled);
};
setGlobal('svs.components.tipsen.hooks.useGetIsMatchNotStartedOrLive', useGetIsMatchNotStartedOrLive);

 })(window);