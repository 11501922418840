(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-debounced-thunk-result.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-debounced-thunk-result.js');
"use strict";

const {
  useState,
  useCallback
} = React;
const {
  useDebounceDispatch
} = svs.components.tipsen.hooks;
const ThunkResult = {
  UNKNOWN: 'UNKNOWN',
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED'
};
const useDebouncedThunkResult = delay => {
  const debounceDispatch = useDebounceDispatch(delay);
  const [thunkState, setThunkState] = useState(ThunkResult.UNKNOWN);
  const thunkCallback = useCallback(action => {
    setThunkState(ThunkResult.PENDING);
    debounceDispatch(action).then(RTK.unwrapResult)
    .then(() => {
      setThunkState(ThunkResult.FULFILLED);
      return undefined;
    }).catch(() => {
      setThunkState(ThunkResult.REJECTED);
    });
  }, [debounceDispatch]);
  return [thunkState, thunkCallback];
};
setGlobal('svs.components.tipsen.hooks.debouncedThunkResult', {
  useDebouncedThunkResult,
  ThunkResult
});

 })(window);