(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-debounce-dispatch.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-debounce-dispatch.js');
"use strict";

const {
  useCallback,
  useRef
} = React;
const {
  useDispatch
} = ReactRedux;
const useDebounceDispatch = function () {
  let delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 250;
  delay = Math.max(0, delay);
  const dispatch = useDispatch();
  const debounceTimer = useRef(null);
  return useCallback(fn => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    return new Promise(resolve => {
      debounceTimer.current = setTimeout(() => {
        resolve(dispatch(fn));
      }, delay);
    });
  }, [delay, dispatch]);
};
setGlobal('svs.components.tipsen.hooks.useDebounceDispatch', useDebounceDispatch);

 })(window);