(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-is-current-draw-playable.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-is-current-draw-playable.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  selectById
} = svs.components.sport.drawsRedux.globalizedSelectors;
const {
  DrawState,
  DisableType
} = svs.components.sport.tipsenShared;
const {
  getCompetitionFactoryInstance
} = svs.components.tipsen.competition.competitionFactoryInstance;
const isDrawOpened = draw => {
  var _draw$drawStateId;
  return ((_draw$drawStateId = draw === null || draw === void 0 ? void 0 : draw.drawStateId) !== null && _draw$drawStateId !== void 0 ? _draw$drawStateId : DrawState.Undefined) === DrawState.Opened;
};
const isDrawBetDisabled = draw => {
  var _draw$regBetDisabled;
  return ((_draw$regBetDisabled = draw === null || draw === void 0 ? void 0 : draw.regBetDisabled) !== null && _draw$regBetDisabled !== void 0 ? _draw$regBetDisabled : DisableType.Regbet) === DisableType.NotDisabled;
};
const isDrawPlayable = draw => {
  if (!draw) {
    return false;
  }
  return isDrawOpened(draw) && isDrawBetDisabled(draw);
};
const useIsDrawRegBetDisabled = (productId, drawNumber) => {
  const drawId = "".concat(productId, "_").concat(drawNumber);
  const draw = useSelector(state => selectById(state, drawId));
  return isDrawBetDisabled(draw);
};
const useIsDrawPlayable = (productId, drawNumber) => {
  const drawId = "".concat(productId, "_").concat(drawNumber);
  const draw = useSelector(state => selectById(state, drawId));
  return isDrawPlayable(draw);
};
const useIsCurrentDrawPlayable = drawId => {
  const {
    useCanPlayInCompetitionDraw,
    useActiveCompetitionId
  } = getCompetitionFactoryInstance().getCompetitionHooksForCms();
  const draw = useCurrentDraw(drawId);
  const activeCompetitionId = useActiveCompetitionId();
  const canPlayInCompetitionDraw = useCanPlayInCompetitionDraw({
    productId: draw.productId,
    drawNumber: draw.drawNumber
  });
  const isDrawOpened = isDrawPlayable(draw);
  return activeCompetitionId ? isDrawOpened && canPlayInCompetitionDraw : isDrawOpened;
};
setGlobal('svs.components.tipsen.hooks', {
  useIsDrawRegBetDisabled,
  useIsCurrentDrawPlayable,
  useIsDrawPlayable
});

 })(window);