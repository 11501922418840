(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-branding.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-branding.js');
"use strict";

const {
  useMemo
} = React;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const {
  useIsCompetitionInCurrentProduct
} = svs.components.marketplaceCompetition.services.hooks;
const {
  SubProductBrand
} = svs.components.lbUtils.brandMapping.enum;
const {
  CompetitionType
} = svs.components.marketplaceCompetition.constants;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;

const useBranding = function (drawId) {
  var _competitionHandler, _competition$details;
  let drawCompetitionId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const definition = useDefinition();
  const draw = useCurrentDraw(drawId);
  const brandingList = useMemo(() => definition.getBrandNamesByDraw(draw), [definition, draw]);
  const isCompetitionIncludedInBrandingList = brandingList.includes(SubProductBrand.TIPS_SM) || brandingList.includes(SubProductBrand.TIPS_EM);
  const competition = (_competitionHandler = competitionHandler()) === null || _competitionHandler === void 0 ? void 0 : _competitionHandler.getActiveCompetition(draw === null || draw === void 0 ? void 0 : draw.productId);
  const compType = competition === null || competition === void 0 ? void 0 : competition.compType;
  const isCompetitionInCurrentProduct = useIsCompetitionInCurrentProduct(competition === null || competition === void 0 || (_competition$details = competition.details) === null || _competition$details === void 0 ? void 0 : _competition$details.productId, definition === null || definition === void 0 ? void 0 : definition.drawProductIds);
  const removeCompetitionBranding = isCompetitionIncludedInBrandingList && !isCompetitionInCurrentProduct && !drawCompetitionId || isCompetitionIncludedInBrandingList && drawId && !drawCompetitionId;
  const addCompetitionBrandingForDraw = !isCompetitionIncludedInBrandingList && drawId && drawCompetitionId && compType;
  const filteredBrandingList = useMemo(() => {
    let filteredList = brandingList;
    if (removeCompetitionBranding) {
      filteredList = brandingList.filter(brandingItem => brandingItem !== SubProductBrand.TIPS_SM && brandingItem !== SubProductBrand.TIPS_EM);
    }
    if (addCompetitionBrandingForDraw) {
      filteredList.push(compType === CompetitionType.TipsEM ? SubProductBrand.TIPS_EM : SubProductBrand.TIPS_SM);
    }
    return filteredList;
  }, [addCompetitionBrandingForDraw, brandingList, compType, removeCompetitionBranding]);
  return filteredBrandingList;
};
setGlobal('svs.components.tipsen.hooks.useBranding', useBranding);

 })(window);