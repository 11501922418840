(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-get-competition-status.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-get-competition-status.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  useEffect
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  fetchCompetition
} = svs.components.marketplaceCompetition.services.actions.competitionActions;
const {
  selectCompetition,
  selectCompetitionStatus
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const {
  competitionsSupportedForProduct
} = svs.components.tipsen.engine.utils.competitionHelpers;
const {
  FetchState
} = svs.components.marketplaceCompetition.constants;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const useGetCompetitionStatus = (engineDefinition, competitionId) => {
  const dispatch = useDispatch();
  const competition = useSelector(state => selectCompetition(state, competitionId));
  const competitionStatus = useSelector(state => selectCompetitionStatus(state, competitionId));
  const hasCompetitionData = Boolean(competition);
  const competitionsSupported = competitionsSupportedForProduct(engineDefinition);
  const returnValueForLoadingState = competitionsSupported && !competitionStatus ? FetchState.Idle : competitionStatus;
  useEffect(() => {
    if (!hasCompetitionData && competitionId && competitionsSupported && !isSelfService) {
      const shouldFetchNewCompetition = false;
      dispatch(fetchCompetition({
        competitionId,
        shouldFetchNewCompetition
      }));
    }
  }, [hasCompetitionData, competitionId, dispatch, competitionsSupported]);
  return !competitionsSupported ? undefined : returnValueForLoadingState;
};
setGlobal('svs.components.tipsen.hooks.useGetCompetitionStatus', useGetCompetitionStatus);

 })(window);