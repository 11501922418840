(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-clear-coupon.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-clear-coupon.js');
"use strict";

const {
  useCallback
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  clearCoupon
} = svs.components.tipsen.engine.actions;
const {
  selectCouponEmpty
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  Confirm
} = svs.components.dialog;
const useClearCoupon = couponId => {
  const dispatch = useDispatch();
  const isCouponEmpty = useSelector(state => selectCouponEmpty(state, couponId));
  return useCallback(() => {
    if (!isCouponEmpty) {
      svs.components.dialog.api.add(new Confirm({
        actions: [{
          title: 'Avbryt'
        }, {
          callback() {
            svs.components.dialog.api.close();
            dispatch(clearCoupon({
              couponId
            }));
          },
          title: 'Rensa'
        }],
        message: [{
          text: 'Vill du fortsätta ändå?',
          type: svs.components.dialog.message.TEXT
        }],
        branding: svs.components.dialog.branding.SPORT,
        icon: 'dustbin',
        title: 'Dina tecken kommer att rensas'
      }));
    }
  }, [couponId, dispatch, isCouponEmpty]);
};
setGlobal('svs.components.tipsen.hooks.useClearCoupon', useClearCoupon);

 })(window);