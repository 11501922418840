(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-current-competitions.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-current-competitions.js');
"use strict";

const {
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  selectCompetitionsByProductDraw
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const useCurrentCompetitions = () => {
  const currentDraw = useCurrentDraw();
  const competitionsByDraw = useSelector(state => selectCompetitionsByProductDraw(state, currentDraw === null || currentDraw === void 0 ? void 0 : currentDraw.productId, currentDraw === null || currentDraw === void 0 ? void 0 : currentDraw.drawNumber));
  return useMemo(() => competitionsByDraw, [competitionsByDraw]);
};
setGlobal('svs.components.tipsen.hooks.useCurrentCompetitions', useCurrentCompetitions);

 })(window);