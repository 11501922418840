(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-sport-class.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-sport-class.js');
"use strict";

const {
  useEffect
} = React;
const {
  SportTypeToString
} = svs.components.sport.sportTypes;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const useSportClass = el => {
  const draw = useCurrentDraw();
  useEffect(() => {
    var _SportTypeToString$dr;
    el.classList.add('sport-background');
    el.dataset.sport = (_SportTypeToString$dr = SportTypeToString[draw.sportType]) !== null && _SportTypeToString$dr !== void 0 ? _SportTypeToString$dr : 'none';
  }, [draw.sportType, el.classList, el.dataset]);
};
setGlobal('svs.components.tipsen.hooks.useSportClass', useSportClass);

 })(window);