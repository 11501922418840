(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-is-tipsinfo-enabled.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-is-tipsinfo-enabled.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useMemo,
  useContext
} = React;
const {
  useSelector
} = ReactRedux;
const {
  TipsinfoTypes
} = svs.components.tipsen.engine.constants;
const {
  selectTipsinfo,
  selectShowMatchAnalyseForecast,
  selectOutcomeInfoChoise
} = svs.components.tipsen.selectors;
const {
  useIsTipsinfoSupported
} = svs.components.tipsen.hooks;
const {
  capitalizeFirstLetter
} = svs.components.lbUtils.capitalizeFirstLetter;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const useIsTipsinfoEnabled = () => {
  const {
    pageType
  } = useContext(RenderContext);
  const tipsinfoSupport = useIsTipsinfoSupported(pageType);
  const tipsinfoState = useSelector(state => selectTipsinfo(state, pageType));
  const outcomeInfoChoice = useSelector(state => selectOutcomeInfoChoise(state, pageType));

  const isBetPredictionSupportedAndEnabled = useSelector(state => selectShowMatchAnalyseForecast(state, pageType));
  const tipsinfoShorthands = useMemo(() => {
    const obj = {};
    for (const key in tipsinfoState) {
      if (tipsinfoState[key]) {
        const capitalizedKey = capitalizeFirstLetter(key);
        const isSupportedKey = "is".concat(capitalizedKey, "Supported");
        obj["is".concat(capitalizedKey, "Enabled")] = tipsinfoSupport[isSupportedKey] && tipsinfoState[key].state;
      }
    }
    obj.isBetPredictionEnabled = isBetPredictionSupportedAndEnabled;
    obj.isAnyTipsinfoEnabled = Object.values(obj).some(value => Boolean(value));
    return obj;
  }, [isBetPredictionSupportedAndEnabled, tipsinfoSupport, tipsinfoState]);
  const isSvenskaFolketAsOutcomesEnabled = useMemo(() => {
    const outcomeLabelType = outcomeInfoChoice.display;
    return outcomeLabelType === TipsinfoTypes.SvenskaFolket;
  }, [outcomeInfoChoice]);
  return _objectSpread(_objectSpread({}, tipsinfoShorthands), {}, {
    isSvenskaFolketAsOutcomesEnabled
  });
};
setGlobal('svs.components.tipsen.hooks.useIsTipsinfoEnabled', useIsTipsinfoEnabled);

 })(window);