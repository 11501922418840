(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-get-participants.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-get-participants.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  createBetEventKey
} = svs.components.sportinfo.common.helpers;
const {
  selectBetEvent,
  selectBetEventsByDraw,
  selectParticipant,
  selectAllParticipants
} = svs.components.sportinfo.matchInfoRedux.selectors;
const useGetParticipantsByDraw = (productId, drawNumber) => {
  const betEvents = useSelector(state => selectBetEventsByDraw(state, productId, drawNumber));
  const allParticipants = useSelector(state => selectAllParticipants(state));
  if (!betEvents) {
    return [];
  }
  const betEventsArray = Object.entries(betEvents).map(_ref => {
    let [betEventId, betEvent] = _ref;
    return {
      betEventId,
      participants: betEvent.participants
    };
  });
  betEventsArray.sort((current, next) => current.betEventId - next.betEventId);
  const participantsByBetEvent = betEventsArray.map(betEvent => {
    var _betEvent$participant;
    const [homeParticipantId, awayParticipantId] = (_betEvent$participant = betEvent === null || betEvent === void 0 ? void 0 : betEvent.participants) !== null && _betEvent$participant !== void 0 ? _betEvent$participant : [];
    const homeParticipant = allParticipants[homeParticipantId];
    const awayParticipant = allParticipants[awayParticipantId];
    return [homeParticipant, awayParticipant];
  });
  return participantsByBetEvent;
};
const useGetParticipantsByEvent = (productId, drawNumber, betEventNumber) => {
  const betEvent = useSelector(state => selectBetEvent(state, createBetEventKey(productId, drawNumber, betEventNumber)));
  const homeParticipant = useSelector(state => selectParticipant(state, betEvent !== null && betEvent !== void 0 && betEvent.participants[0] ? betEvent === null || betEvent === void 0 ? void 0 : betEvent.participants[0] : undefined));
  const awayParticipant = useSelector(state => selectParticipant(state, betEvent !== null && betEvent !== void 0 && betEvent.participants[1] ? betEvent === null || betEvent === void 0 ? void 0 : betEvent.participants[1] : undefined));
  return [homeParticipant, awayParticipant];
};
setGlobal('svs.components.tipsen.hooks', {
  useGetParticipantsByDraw,
  useGetParticipantsByEvent
});

 })(window);