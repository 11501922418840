(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-active-competition-desc.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-active-competition-desc.js');
"use strict";

const {
  resolveCompetitionName
} = svs.components.marketplaceCompetition.helpers;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const {
  selectSupportsCompetitions
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useSelector
} = ReactRedux;
const useGetActiveCompetitionDesc = productId => {
  let competition;
  const supportsCompetition = useSelector(selectSupportsCompetitions);
  if (supportsCompetition) {
    var _compHandler$getActiv;
    const compHandler = competitionHandler();
    competition = compHandler === null || compHandler === void 0 || (_compHandler$getActiv = compHandler.getActiveCompetition(productId)) === null || _compHandler$getActiv === void 0 ? void 0 : _compHandler$getActiv.getData();
  }
  const competitionDesc = resolveCompetitionName(competition);
  return competitionDesc;
};
setGlobal('svs.components.tipsen.hooks.useGetActiveCompetitionDesc', useGetActiveCompetitionDesc);

 })(window);