(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-locked-draw.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-locked-draw.js');
"use strict";

const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  useLocation
} = ReactRouterDOM;
const {
  useSelector
} = ReactRedux;
const {
  selectActiveWallet
} = svs.components.tipsen.walletSelectors;
const {
  WALLET_MARKETPLACE_PLAYER,
  WALLET_MARKETPLACE_RETAILER
} = svs.components.tipsen.wallet.walletType;
const marketplaceWalletTypes = [WALLET_MARKETPLACE_PLAYER, WALLET_MARKETPLACE_RETAILER];
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const useLockedDraw = () => {
  const location = useLocation();
  const isExternalInspect = location.pathname.includes("/".concat(TipsenModuleGameUrl.EXTERNAL, "/granska"));
  const selectedWallet = useSelector(selectActiveWallet);
  const isMarketplaceWallet = selectedWallet && marketplaceWalletTypes.includes(selectedWallet.type);
  const shouldLockDraw = isExternalInspect || isMarketplaceWallet;
  const draw = useCurrentDraw();
  return shouldLockDraw ? {
    productId: draw === null || draw === void 0 ? void 0 : draw.productId,
    drawNumber: draw === null || draw === void 0 ? void 0 : draw.drawNumber
  } : null;
};
setGlobal('svs.components.tipsen.hooks.useLockedDraw', useLockedDraw);

 })(window);