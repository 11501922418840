(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-active-competition-type.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-active-competition-type.js');
"use strict";

const {
  useCompetitionType
} = svs.components.marketplaceCompetition.services.hooks;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
const {
  selectSupportsCompetitions
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useSelector
} = ReactRedux;
const useGetActiveCompetitionType = productId => {
  let activeCompetitionId;
  const supportsCompetition = useSelector(selectSupportsCompetitions);
  if (supportsCompetition) {
    var _activeCompetition$ge;
    const compHandler = competitionHandler();
    const activeCompetition = compHandler === null || compHandler === void 0 ? void 0 : compHandler.getActiveCompetition(productId);
    activeCompetitionId = (_activeCompetition$ge = activeCompetition === null || activeCompetition === void 0 ? void 0 : activeCompetition.getCompetitionId()) !== null && _activeCompetition$ge !== void 0 ? _activeCompetition$ge : undefined;
  }
  const competitionType = useCompetitionType(activeCompetitionId);
  return competitionType;
};
setGlobal('svs.components.tipsen.hooks.useGetActiveCompetitionType', useGetActiveCompetitionType);

 })(window);