(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-get-all-participant-names-by-draw-id.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-get-all-participant-names-by-draw-id.js');
"use strict";


const {
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  selectBetEvents,
  selectAllParticipants
} = svs.components.sportinfo.matchInfoRedux.selectors;
const useGetAllParticipantNamesByDrawId = drawId => {
  const betEvents = useSelector(state => selectBetEvents(state));
  const participants = useSelector(state => selectAllParticipants(state));
  const participantNames = useMemo(() => {
    const currentBetEvents = Object.keys(betEvents).filter(betEventId => betEventId.startsWith(drawId)).sort((a, b) => a - b);
    return currentBetEvents.map(betEventId => betEvents[betEventId].participants.map(pId => participants[pId].mediumName || participants[pId].name));
  }, [betEvents, drawId, participants]);
  return participantNames;
};
setGlobal('svs.components.tipsen.hooks.useGetAllParticipantNamesByDrawId', useGetAllParticipantNamesByDrawId);

 })(window);