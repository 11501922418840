(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-is-coupon-board-empty.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-is-coupon-board-empty.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  selectCouponBoardEmpty
} = svs.components.tipsen.engine.selectors.couponSelectors;
const useIsCouponBoardEmpty = (couponId, index) => useSelector(state => selectCouponBoardEmpty(state, couponId, index));
setGlobal('svs.components.tipsen.hooks.useIsCouponBoardEmpty', useIsCouponBoardEmpty);

 })(window);