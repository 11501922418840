(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-tipsinfo-support.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-tipsinfo-support.js');
"use strict";


const {
  useMemo,
  useContext
} = React;
const {
  useSelector
} = ReactRedux;
const {
  selectTipsinfoSupport
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const useTipsinfoSupport = () => {
  const {
    pageType
  } = useContext(RenderContext);
  const tipsinfoSupport = useSelector(state => selectTipsinfoSupport(state, pageType));
  const allSupportedTipsinfo = useMemo(() => Object.keys(tipsinfoSupport).filter(key => tipsinfoSupport[key]).sort(), [tipsinfoSupport]);
  const allNonSupportedTipsinfo = useMemo(() => Object.keys(tipsinfoSupport).filter(key => !tipsinfoSupport[key]).sort(), [tipsinfoSupport]);
  return {
    tipsinfoSupport,
    allSupportedTipsinfo,
    allNonSupportedTipsinfo
  };
};
setGlobal('svs.components.tipsen.hooks.useTipsinfoSupport', useTipsinfoSupport);

 })(window);