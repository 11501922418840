(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks/assets/javascripts/use-is-coupon-empty.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks/assets/javascripts/use-is-coupon-empty.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  selectCouponEmpty
} = svs.components.tipsen.engine.selectors.couponSelectors;
const useIsCouponEmpty = couponId => useSelector(state => selectCouponEmpty(state, couponId));
setGlobal('svs.components.tipsen.hooks.useIsCouponEmpty', useIsCouponEmpty);

 })(window);